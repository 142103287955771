import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/Router';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
ReactGA.initialize('G-6JMDPV5RZK');

function App() {
  return (
    <div className="App">
      <BrowserRouter
        basename={process.env.PUBLIC_URL}
      >
        <Router />
      </BrowserRouter>
      <Helmet>
        <title>Parampara Cold Pressed Oil</title>
        <meta name="description" content="A traditional approach to health" />
        <meta name="theme-color" content="#008f68" />
      
  <meta property="og:url" content="https://paramparaoils.in/"/> 
  <meta property="og:type" content="website"/>
  <meta property="og:title" content="Parampara Cold Pressed Oil"/>
  <meta property="og:description" content="A traditional approach to health"/>
  <meta property="og:image" content="https://file.notion.so/f/s/f559c66f-62fc-4f35-a96f-118c2106ba7e/Untitled.png?id=ef6b2a6b-e437-4e6c-9c4c-ddc56ec10e77&table=block&spaceId=ec08ff7e-d564-4aee-a3df-702a5cf8a834&expirationTimestamp=1680131004903&signature=9UJi2GuudKnmBMdx8vmHWCFVAki-rOhaEfRIsL43t-A&downloadName=Untitled.png"/>

  <meta name="twitter:card" content="summary_large_image"/>
  <meta property="twitter:domain" content="paramparaoils.in"/>
  <meta property="twitter:url" content="https://paramparaoils.in/"/>
  <meta name="twitter:title" content="Parampara Cold Pressed Oil"/>
  <meta name="twitter:description" content="A traditional approach to health"/>
  <meta name="twitter:image" content="https://file.notion.so/f/s/f559c66f-62fc-4f35-a96f-118c2106ba7e/Untitled.png?id=ef6b2a6b-e437-4e6c-9c4c-ddc56ec10e77&table=block&spaceId=ec08ff7e-d564-4aee-a3df-702a5cf8a834&expirationTimestamp=1680131004903&signature=9UJi2GuudKnmBMdx8vmHWCFVAki-rOhaEfRIsL43t-A&downloadName=Untitled.png"/>
      </Helmet>
    </div>
  );
}

export default App;
