import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box  
} from '@chakra-ui/react'

import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
const Accordian = () => {
  return (
    <div className='min-h-[200px]'>
      <Accordion allowToggle className={`rounded-xl`}>
        <AccordionItem className=''>
          {({isExpanded})=>(
            <div className={`${isExpanded?'bg-[#F4F9F4]':''} p-[24px] rounded-[8px]`}>
          <h2>
            <AccordionButton className='mb-5 text-Small/Title/xSmall md:text-Medium+/Title/xSmall text-[#262626]'>
              <Box flex='1' textAlign='left'>
              Is cold pressed oil healthier than refined oil?
              </Box>
              {isExpanded ? (
              <AiOutlineMinus fontSize='16px' fontWeight={600} className='text-[#228B22]' />
            ) : (
              <AiOutlinePlus fontSize='16px' fontWeight={600} className='text-[#228B22]' />
            )}
            </AccordionButton>
          </h2>
          <AccordionPanel className='text-[#494949] text-Small/Paragraph/Medium md:text-Medium+/Paragraph/Medium'>
          Cold pressed oil does not contain any chemicals and is pressed without heating the seeds to extreme temperature. Unlike refined oil, cold pressed oils retain the original aroma of the oil seed, they maintain the nutritional content from the original seed as no chemical treatment is done and hence are healthier.
          </AccordionPanel>
          </div>
          )}
        </AccordionItem>
        <AccordionItem className=''>
          {({isExpanded})=>(
            <div className={`${isExpanded?'bg-[#F4F9F4]':''} p-[24px] rounded-[8px]`}>
          <h2>
            <AccordionButton className='mb-5 text-Small/Title/xSmall md:text-Medium+/Title/xSmall text-[#262626]'>
              <Box flex='1' textAlign='left'>
              Why is cold pressed oil expensive than refined oil?
              </Box>
              {isExpanded ? (
              <AiOutlineMinus fontSize='16px' fontWeight={600} className='text-[#228B22]' />
            ) : (
              <AiOutlinePlus fontSize='16px' fontWeight={600} className='text-[#228B22]' />
            )}
            </AccordionButton>
          </h2>
          <AccordionPanel className='text-[#494949] text-Small/Paragraph/Medium md:text-Medium+/Paragraph/Medium'>
          The cost of production of cold pressed oil is higher as compared to refined oils as our process involves extraction using pressing the seeds which yields lesser % of oil from the raw material. We do not add any solvents nor do we heat it to extreme temperature to increase the output. Doing that will definitely increase the quantity of oil from the raw material but the oil will lose it's inherent nutritional and aromatic properties. This is the reason it costs more than refined oil. 
          </AccordionPanel>
          </div>
          )}
        </AccordionItem>

        <AccordionItem className=''>
          {({isExpanded})=>(
            <div className={`${isExpanded?'bg-[#F4F9F4]':''} p-[24px] rounded-[8px]`}>
          <h2>
            <AccordionButton className='mb-5 text-Small/Title/xSmall md:text-Medium+/Title/xSmall text-[#262626]'>
              <Box flex='1' textAlign='left'>
              What can I use cold pressed oil for apart from cooking?
              </Box>
              {isExpanded ? (
              <AiOutlineMinus fontSize='16px' fontWeight={600} className='text-[#228B22]' />
            ) : (
              <AiOutlinePlus fontSize='16px' fontWeight={600} className='text-[#228B22]' />
            )}
            </AccordionButton>
          </h2>
          <AccordionPanel className='text-[#494949] text-Small/Paragraph/Medium md:text-Medium+/Paragraph/Medium'>
          Given the purity of the oils, you can safely use cold pressed coconut oil as a moisturiser or as a hair oil. Sesame oil also acts as a good skin moisturiser that can be used in winters for dry skin.  
          </AccordionPanel>
          </div>
          )}
        </AccordionItem>
      </Accordion>

    </div>
  )
}

export default Accordian